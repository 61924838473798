import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import { useOutsideClick } from "rooks";

import Body4 from "common/Typography/Body4";
import useAuth from "common/hooks/useAuth";
import { generateAppUrl } from "../../../util/utils";
import Cookies from "js-cookie";

interface IArrowIconProps {
  rotate?: number;
}

interface IStyleProps {
  dropdownOpen: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
      "& [data-component=typography-body-4]": {
        userSelect: "none",
      },
    },
    selector: ({ dropdownOpen }: IStyleProps) => {
      return {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        padding: "0.125rem 0.5rem",
        width: "100%",
        backgroundColor: !dropdownOpen ? "#0C0B0D" : "#434248",
        // borderTopWidth: "1px",
        // borderRightWidth: "1px",
        // borderBottomWidth: !dropdownOpen ? "1px" : 0,
        // borderLeftWidth: "1px",
        // borderTopStyle: "solid",
        // borderRightStyle: "solid",
        // borderBottomStyle: "solid",
        // borderLeftStyle: "solid",
        // borderTopColor: "#D5D5D6",
        // borderRightColor: "#D5D5D6",
        // borderBottomColor: "#D5D5D6",
        // borderLeftColor: "#D5D5D6",
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
        borderBottomRightRadius: !dropdownOpen ? "4px" : "unset",
        borderBottomLeftRadius: !dropdownOpen ? "4px" : "unset",
        flex: "none",
        order: 0,
        flexGrow: 0,
        cursor: "pointer",

        "&:hover": {
          backgroundColor: "#434248",
        },
      };
    },

    selectorMain: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },

    selectorText: {
      margin: "0.5rem",
    },

    separateLineContainer: {
      height: "1px",
      width: "100%",
      paddingTop: "4px",
      paddingBottom: "8px",
      paddingLeft: "8px",
      paddingRight: "11px",
      backgroundColor: "#434248",
      // borderLeft: "1px solid #D5D5D6",
      // borderRight: "1px solid #D5D5D6",
    },

    separateLineItem: {
      height: "1px",
      width: "100%",
      backgroundColor: "#63626A",
    },

    dropdown: {
      position: "absolute",
      width: "100%",
      backgroundColor: "#434248",
      borderBottomRightRadius: "4px",
      borderBottomLeftRadius: "4px",
      overflow: "hidden",

      [theme.breakpoints.down("sm")]: {
        right: 0,
        borderTopLeftRadius: "4px",
      },
    },

    dropdownItem: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      padding: "8px",
      backgroundColor: "#434248",
      flex: "none",
      order: 0,
      flexGrow: 0,
      cursor: "pointer",

      "&:hover": {
        backgroundColor: "#63626A",
      },
    },
  })
);

const Avatar = (): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
      data-component="dropdown-avatar"
    >
      <rect width="32" height="32" fill="#6C2BFF" rx="16"></rect>
      <path
        fill="#fff"
        d="M22 22.667a.667.667 0 01-1.333 0 4.667 4.667 0 10-9.334 0 .667.667 0 11-1.333 0 6 6 0 0112 0zM16 16a3.667 3.667 0 110-7.333A3.667 3.667 0 0116 16zm0-1.333A2.333 2.333 0 1016 10a2.333 2.333 0 000 4.667z"
      ></path>
    </svg>
  );
};

const ArrowIcon = ({ rotate = 0 }: IArrowIconProps): JSX.Element => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      data-testid="custom-icon"
    >
      <path
        d="M3.524 5.504a.534.534 0 00-.19.398c0 .143.063.285.19.399l4.023 4.195a.687.687 0 00.906 0l4.023-4.181a.526.526 0 000-.81.687.687 0 00-.906 0L7.992 9.301 4.43 5.504a.721.721 0 00-.906 0z"
        fill="#ffffff"
      ></path>
    </svg>
  );
};

const AccountDropdown = (): JSX.Element => {
  const containerEl = React.useRef(null);
  const { isLoggedin, logout } = useAuth();
  const [open, setOpen] = React.useState(false);
  const classes = useStyles({ dropdownOpen: open } as IStyleProps);
  const activePackage = Cookies.get("hfa-active-package");

  const handleOpenDropdown = () => {
    setOpen(true);
  };

  const handleCloseDropdown = () => {
    setOpen(false);
  };

  const handleToogleDropdown = () => {
    if (open) {
      handleCloseDropdown();
    } else {
      handleOpenDropdown();
    }
  };

  const handleGoingToSetting = () => {
    window.location.href = generateAppUrl(activePackage === "hfm" ? "settings/account" : "account/details");
  };

  const handleLogout = () => {
    if (isLoggedin) {
      logout();
    }
  };

  useOutsideClick(containerEl, handleCloseDropdown);

  return (
    <div
      data-component="account-dropdown"
      ref={containerEl}
      className={classes.root}
    >
      <div>
        <div className={classes.selector} onClick={handleToogleDropdown}>
          <div className={classes.selectorMain}>
            <Avatar />
            <Hidden smDown>
              <Body4 className={classes.selectorText} variant="bold">
                My Account
              </Body4>
            </Hidden>
          </div>

          <Hidden smDown>
            <ArrowIcon rotate={open ? 180 : 0} />
          </Hidden>
        </div>
      </div>

      {open && (
        <div className={classes.dropdown}>
          <div className={classes.separateLineContainer}>
            <div className={classes.separateLineItem} />
          </div>
          <div
            className={classes.dropdownItem}
            onClick={() => handleGoingToSetting()}
          >
            <Body4 variant="bold">Settings</Body4>
          </div>

          <div className={classes.dropdownItem} onClick={() => handleLogout()}>
            <Body4 variant="bold">Logout</Body4>
          </div>
        </div>
      )}
    </div>
  );
};

export default AccountDropdown;
