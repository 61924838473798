import React from "react";
import clsx from "clsx";

import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import Logo from "common/Logo";
import navItems from "./nav-items";
import Body4 from "common/Typography/Body4";
import AccountDropdown from "./AccountDropdown";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    appBar: {
      minHeight: "68px",
      zIndex: theme.zIndex.drawer + 2,
      backgroundColor: "#0C0B0D",
      borderBottom: "1px solid #63626A",
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.down("sm")]: {
        left: 0,
        width: "100vw",
      },
    },
    appBarShift: {
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    toolbar: {
      margin: "0px 2rem 0px 1rem",
      padding: "0",
      minHeight: "68px",
    },
    logoContainer: {
      display: "flex",
      alignItems: "center",

      "& > :first-child": {
        marginRight: "20px",

        [theme.breakpoints.up("md")]: {
          marginRight: 0,
        },
      },
    },
    navContainer: {
      marginLeft: "3rem",
      display: "flex",
      alignItems: "center",
      position: "relative",

      "& > span": {
        padding: "0px 1rem",
        cursor: "pointer",
      },
    },
    navItem: {
      cursor: "pointer",
    },
  })
);

interface IProps {
  data?: any | null;
  error?: any | null;
  match?: any | null;
  className?: string;
  isOpen: boolean;
  onMobileNavOpen: () => void;
  setOpen: any;
}

interface IMenuButtonProps {
  onClick: () => void;
}

const MenuButton = ({ onClick }: IMenuButtonProps): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      style={{
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <path
        fill="#fff"
        d="M15.148 6H4.852C4.382 6 4 5.553 4 5c0-.553.381-1 .852-1h10.296c.47 0 .852.447.852 1 0 .553-.381 1-.852 1zM19.17 13H4.83C4.37 13 4 12.553 4 12c0-.553.371-1 .83-1h14.34c.459 0 .83.447.83 1 0 .553-.371 1-.83 1zM15.148 20H4.852C4.382 20 4 19.553 4 19c0-.553.381-1 .852-1h10.296c.47 0 .852.447.852 1 0 .553-.381 1-.852 1z"
      ></path>
    </svg>
  );
};

const TopBar = ({
  className,
  onMobileNavOpen,
  isOpen,
  setOpen,
  match,
  ...rest
}: IProps) => {
  const classes = useStyles();

  const handleDrawerOpen = () => {
    onMobileNavOpen();
  };

  const handleClick = (e: any, href: string, target: string) => {
    try {
      const fullUrl = new URL(href);

      if (fullUrl) {
        e.preventDefault();
      }
    } catch {
      return;
    }

    if (target === "_blank") {
      window.open(href, "_blank");
      return;
    }

    window.location.href = href;
  };

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: isOpen,
      })}
      elevation={0}
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <div className={classes.logoContainer}>
          <Logo />

          <Hidden mdUp>
            <MenuButton onClick={handleDrawerOpen} />
          </Hidden>
        </div>

        <Hidden smDown>
          <div className={classes.navContainer}>
            {navItems.map((navItem) => (
              <>
                <Body4
                  key={navItem.href}
                  variant="bold"
                  onClick={(e: any) =>
                    handleClick(e, navItem.href, navItem.target)
                  }
                  color={
                    navItem.title === "Hedge Fund Alert" ? "#9B75FF" : undefined
                  }
                  className={classes.navItem}
                >
                  {navItem.title}
                </Body4>
              </>
            ))}
          </div>
        </Hidden>

        <Box flexGrow={1} />

        <AccountDropdown />
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
