import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

import Body4 from "common/Typography/Body4";
import { generateAppUrl } from "../util/utils";
import React from "react";

interface IProps {
  heading?: string;
  summary?: string;
  children?: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleHeader: {
      marginBottom: theme.spacing(1),
      color: "#FFFFFF",
    },
    headerContainer: {
      width: "100%",
      position: "fixed",
      zIndex: theme.zIndex.drawer + 1,
      top: 65,
      left: 0,
      padding: 0,

      [theme.breakpoints.down("xs")]: {
        top: 57,
      },

      // marginLeft: theme.spacing(-3),
      // marginRight: theme.spacing(-3),
      // marginBottom: theme.spacing(2),
      // [theme.breakpoints.up("md")]: {
      //   paddingLeft: theme.spacing(0),
      //   paddingRight: theme.spacing(0),
      //   marginTop: theme.spacing(-3),
      // },
    },
    contentHeader: {
      padding: theme.spacing(3),
      backgroundColor: "#26262C",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "28px",

      [theme.breakpoints.up("md")]: {
        flexDirection: "row",
      },
    },
    contentBack: {
      cursor: "pointer",
      display: "flex",
      gap: "2px",
      color: "white",
    },
    backButton: {
      fontWeight: "bold",
      whiteSpace: "nowrap",
    },
  })
);

const ArrowIcon = (): JSX.Element => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.072 16.3418C13.8426 16.5602 13.4796 16.5514 13.2611 16.322L9.6885 12.5708C9.33689 12.2016 9.33689 11.6214 9.6885 11.2522L13.2611 7.501C13.4796 7.27162 13.8426 7.26276 14.072 7.48122C14.3014 7.69969 14.3102 8.06274 14.0918 8.29212L10.6447 11.9115L14.0918 15.5309C14.3102 15.7603 14.3014 16.1233 14.072 16.3418Z"
        fill="white"
      />
    </svg>
  );
};

const Banner = ({ heading, summary, children }: IProps): JSX.Element => {
  const classes = useStyles();

  const handleGoingBack = () => {
    window.location.href = generateAppUrl("insights");
  };

  return (
    <Container className={classes.headerContainer} maxWidth={false}>
      <div className={classes.contentHeader}>
        <div className={classes.contentBack} onClick={handleGoingBack}>
          <ArrowIcon />
          <Typography variant="body2" className={classes.backButton}>Back to Insights</Typography>
        </div>

        <div>
          <Typography
            variant="h6"
            component="h6"
            className={classes.titleHeader}
          >
            {heading}
          </Typography>

          <Body4>{summary}</Body4>
        </div>
      </div>

      {children}
    </Container>
  );
};

export default Banner;
