import { generateAppUrl } from "../../../util/utils";

const items = [
  {
    href: generateAppUrl("insights"),
    icon: null,
    title: "Insights",
    target: "_self",
    defaultActive: false,
  },
  {
    href: generateAppUrl("discover"),
    icon: null,
    title: "Explore",
    target: "_self",
    defaultActive: false,
  },
  {
    href: generateAppUrl("performance"),
    icon: null,
    title: "Performance",
    target: "_self",
    defaultActive: false,
  },
  {
    href: `https://www.withintelligence.com/events-calendar`,
    icon: null,
    title: "Events",
    target: "_blank",
    defaultActive: false,
  },
];

export default items;
