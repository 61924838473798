import { useEffect } from "react";
import clsx from "clsx";
import useRouter from "found/useRouter";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import Toolbar from "@material-ui/core/Toolbar";

import NavItem from "./NavItem";

import items from "../nav-items";
import tabBarItems from "../tab-bar-items";
import { isAdmin } from "common/RedirectNonAdmin";

const drawerWidth = 300;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    name: {},
    mobileDrawer: {
      width: drawerWidth,
      backgroundColor: "#0C0B0D",
    },
    desktopDrawer: {
      width: drawerWidth,
      backgroundColor: "#F2F2FC",
    },
    boxContent: {
      marginTop: "92px",

      [theme.breakpoints.down("sm")]: {
        marginTop: 0,
      },
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1,
      },
    },
    hide: {
      display: "none",
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
  })
);

interface IProps {
  onMobileClose: () => void;
  openMobile: boolean;
  isOpen: boolean;
}

const NavBar = ({
  onMobileClose,
  openMobile,
  isOpen,
}: IProps) => {
  const classes = useStyles();
  const { match } = useRouter();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match!.location]);

  const contentMobile = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      className={classes.boxContent}
    >
      <Box py={0}>
        <List style={{ padding: 0 }}>
          {items.map((item, index) => (
            <NavItem
              index={index}
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
              target={item.target}
              defaultActive={item.defaultActive}
              variant="navdropdown"
            />
          ))}

          {isAdmin() && (
            <NavItem
              index={items.length}
              href="/admin/dashboard"
              title="Admin"
              icon={null}
              target="_self"
            />
          )}
        </List>
      </Box>
    </Box>
  );

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      className={classes.boxContent}
    >
      <Box py={0}>
        <List>
          {tabBarItems.map((item, index) => (
            <NavItem
              index={index}
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
              target={item.target}
            />
          ))}

          {isAdmin() && (
            <NavItem
              index={items.length}
              href="/admin/dashboard"
              title="Admin"
              icon={null}
              target="_self"
            />
          )}
        </List>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {contentMobile}
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Drawer
          anchor="left"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: isOpen,
            [classes.drawerClose]: !isOpen,
          })}
          classes={{
            paper: clsx(classes.desktopDrawer, {
              [classes.drawerOpen]: isOpen,
              [classes.drawerClose]: !isOpen,
            }),
          }}
          open
          variant="permanent"
        >
          <Toolbar />

          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

export default NavBar;
