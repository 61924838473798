import { useCallback, useContext, useEffect, useState } from "react";
import moment from "moment";
import Cookies from "js-cookie";
import { nanoid } from "nanoid";
import { backEndRequest } from "common/request";
import useRouter from "found/useRouter";
import { AuthContext } from "./AuthProvider";
import { openAuthPopup } from "common/AuthPopup";
import { generateAppUrl } from "../../util/utils";

const wiAuthCookieName: string =
  process.env.REACT_APP_WI_AUTH_COOKIE_NAME ?? "";

const request = backEndRequest();

export default function useAuth() {
  const { router } = useRouter();
  const tp = useContext(AuthContext);
  const [isLoggedin, setIsLogged] = useState(tp.isLoggedIn());

  const listener = useCallback(
    (newIsLogged: boolean) => {
      setIsLogged(newIsLogged);
    },
    [setIsLogged]
  );

  useEffect(() => {
    tp.subscribe(listener);

    return () => {
      tp.unsubscribe(listener);
    };
  }, [listener, tp]);

  const login = (newToken: string | null) => {
    tp.setToken(newToken);
    // window.location.reload();
  };

  const logout = () => {
    tp.setToken(null);
    localStorage.removeItem("user");
    request
      .get("/user/logout", {
        withCredentials: true,
      })
      .catch((e) => {
        console.error(e);
      });

    window.location.href = generateAppUrl("logout");

    // window.location.reload();
  };

  const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user")!);
  };

  useEffect(() => {
    if (window.location.pathname === "/") {
      return;
    }

    const wiAccessToken: string = Cookies.get(wiAuthCookieName) ?? "";

    // console.log("wiAuthCookieName", wiAuthCookieName);
    // console.log("wiAccessToken", wiAccessToken);
    // console.log("isLoggedin", isLoggedin);

    if (!wiAccessToken) {
      // return logout();
    }

    if (isLoggedin) {
      return;
    }

    request
      .get("/user/me", {
        params: {
          version: nanoid(),
        },
      })
      .then((res) => {
        const user = res?.data;

        if (user?.error === "Unauthorized") {
          window.location.href = `${generateAppUrl("login")}?redirect=${window.location.href}`;
        }

        if (
          user.status === "suspended" ||
          (user.expireTime &&
            moment(user.expireTime)
              .utcOffset(0)
              .endOf("day")
              .isBefore(moment()))
        ) {
          // force logout user if expireTime passed
          // router.replace("/user/login?expired=1");
          // logout();

          setTimeout(() => {
            openAuthPopup();
          }, 1000);

          return;
        }

        localStorage.setItem(
          "user",
          JSON.stringify({
            ...user,
            companySalesforceId: user?.company?.salesforceId || "",
          })
        );

        login(user.accessToken);

        setTimeout(() => {
          window.location.reload();
        }, 300);
      })
      .catch((e) => {
        // logout();
        console.error(e);
      });
  }, []);

  return {
    getCurrentUser,
    // isLoggedin: tp.isLoggedIn(),
    isLoggedin,
    login,
    logout,
    user: getCurrentUser(),
  };
}

export const isLoggedin = (): Boolean => {
  const wiAccessToken: string = Cookies.get(wiAuthCookieName) ?? "";

  if (!wiAccessToken) {
    return false;
  }

  return !!localStorage.getItem("REACT_TOKEN_AUTH_KEY");
};

export const user = (): Record<string, any> => {
  return JSON.parse(localStorage.getItem("user")!);
};

export const logOut = (): void => {
  localStorage.removeItem("REACT_TOKEN_AUTH_KEY");
  localStorage.removeItem("user");
  window.location.href = generateAppUrl("logout");
  // window.location.reload();
};
