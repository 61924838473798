import * as React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import useRouter from "found/useRouter";

import Spinner from "common/SpinnerFacebook";
import TopBar from "./TopBar";
import NavBar from "./NavBar";
import TabBar from "./TabBar";
import CookieConsentComponent from "common/CookieConsentComponent";

import useIsTablet from "common/hooks/useIsTablet";
import AuthPopup from "common/AuthPopup";
import Banner from "common/Banner";
import useBannerInformation from "common/hooks/useBannerInformation";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      maxWidth: "100vw",
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      marginBottom: "90px",
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,

      [theme.breakpoints.down("xs")]: {
        minHeight: theme.spacing(4),
        marginBottom: "140px",
      },
    },
    content: {
      flexGrow: 1,
      maxWidth: "100vw",
      [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(3),
      },
      [theme.breakpoints.down("sm")]: {
        padding: `${theme.spacing(3)}px 0 0 0`,
        overflow: "hidden",
      },
    },
  })
);

interface IProps {
  data?: any | null;
  error?: any | null;
  children: React.ReactNode;
}

export default function AgentLayout(props: IProps) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [isMobileNavOpen, setMobileNavOpen] = React.useState(false);
  const isTablet = useIsTablet();
  const { match } = useRouter();
  const { information } = useBannerInformation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [match?.location?.pathname]);

  return (
    <div className={classes.root}>
      <Banner heading={information.heading} summary={information.summary} />

      <TopBar
        onMobileNavOpen={() => setMobileNavOpen(true)}
        setOpen={setOpen}
        isOpen={open}
        match={props?.data?.match}
      />

      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        isOpen={open}
      />

      <main className={classes.content}>
        <div className={classes.toolbar} />
        {isTablet && <TabBar />}

        <React.Suspense fallback={<Spinner />}>{props.children}</React.Suspense>
      </main>

      <CookieConsentComponent />

      <AuthPopup />
    </div>
  );
}
